<template>
  <div class="policy-track">
    <div class="top_banner">
      <img src="@/assets/images/news/banner1.jpg" alt="" />
      <div class="top_title">
        <h3>追踪教育新闻 了解政策动态</h3>
        <!-- <p>Track education news，Understand policy dynamics</p> -->
      </div>
    </div>
    <div class="dot_area">
      <img src="@/assets/images/news/dots.png" alt="" />
      <img src="@/assets/images/news/dots.png" alt="" />
    </div>
    <ul class="menu_news">
      <li @click="$router.push('/news/group-dynamics')">
        <div class="title">
          <h3>集团动态</h3>
          <!-- <p>Group Dynamics</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/trade-news')">
        <div class="title">
          <h3>行业新闻</h3>
          <!-- <p>Industry News</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/policy-track')">
        <div class="title">
          <h3 class="color-primary">政策追踪</h3>
          <!-- <p>Policy Tracking</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/public-benefit')">
        <div class="title">
          <h3>晓羊公益行</h3>
          <!-- <p>Xiaoyang Charity Line</p> -->
        </div>
      </li>
    </ul>
    <ul class="content">
      <li v-for="item in dataList" :key="item.id">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="operate_btn">
          <el-button type="primary" size="small" @click="goDetail(item.id)"
            >了解更多 >
          </el-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PolicyTrack',
  data() {
    return {
      dataList: [],
    }
  },
  methods: {
    getDataList() {
      this.$axios
        .post('/news/list', {
          category_id: 4,
          publish_status: 1,
        })
        .then(res => {
          this.dataList = res.list
        })
    },
    goDetail(id) {
      this.$router.push({
        path: '/news/detail',
        query: {
          id: id,
        },
      })
    },
  },
  mounted() {
    this.$store.commit('setPcHeaderIsTransparent', 'transparent')
    this.getDataList()
  },
}
</script>

<style lang="scss" scoped>
%policy {
  .policy-track {
    .top_banner {
      position: relative;
      img {
        width: 100%;
      }
      .top_title {
        position: absolute;
        left: 0;
        @include px2vw('left', 268);
        width: auto;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        color: $--color-white;
        h3 {
          margin-top: 0;
          @include px2vw('font-size', 52);
          margin-bottom: 0 !important;
        }
        p {
          @include px2vw('font-size', 32);
          margin-bottom: 0;
          @include px2vw('margin-top', 20);
        }
        &:after {
          content: '';
          position: absolute;
          left: -34px;
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          width: 6px;
          background-color: $--color-white;
        }
      }
    }
    .dot_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 40%;
      }
    }
    .menu_news {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 100px;
      li {
        text-align: center;
        cursor: pointer;
        h3 {
          margin: 0;
          @include px2vw('padding-left', 80);
          @include px2vw('padding-right', 80);
          @include px2vw('font-size', 36);
          border-right: 1px solid #d8d8d8;
          color: #333;
        }
        p {
          @include px2vw('font-size', 16);
        }
        &:hover h3 {
          color: $--color-primary;
        }
      }
      li:last-child h3 {
        border: none;
      }
    }
    .content {
      width: 90%;
      max-width: 1320px;
      margin: 60px auto;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 1.04167vw));
      @include px2vw('grid-row-gap', 60);
      @include px2vw('grid-column-gap', 40);
      li {
        text-align: center;
        @include px2vw('padding-top', 42);
        @include px2vw('padding-bottom', 42);
        @include px2vw('padding-right', 30);
        @include px2vw('padding-left', 30);
        @include px2vw('font-size', 36);
        // background-color: #ffefe7;
        // border: 1px solid $--color-primary;
        font-size: 18px !important;
        color: #666;
        text-align: justify;
        line-height: 32px;
        font-weight: bold;
        box-shadow: 0px 25px 55px 0px rgba(142, 142, 142, 0.19);
        .title {
          min-height: 100px;
        }
      }
      li:hover {
        cursor: pointer;
      }
    }
  }
}
.view-pc {
  @extend %policy;
}
.view-mobile {
  @extend %policy;
  .policy-track {
    margin-top: 44px;
    .top_banner {
      .top_title {
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .menu_news {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // overflow-x: scroll;
      margin-bottom: 30px;
      li {
        width: 50%;
        margin-bottom: 10px;

        // width: max-content;
        // min-width: 120px;
        h3 {
          font-size: 16px;
          color: #333;
        }
        p {
          font-size: 10px;
        }
      }
    }
    .content {
      display: block;
      margin: 30px auto;
      li {
        margin: 30px auto;
        font-size: 16px !important;
        padding: 20px;
        line-height: 26px;
      }
    }
  }
}
</style>
